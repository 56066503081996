import React, { useRef } from "react"
import { Carousel, Container, Row, Col } from "react-bootstrap"
import { Rerousel } from "rerousel"

import TelesisLogo from "../../images/logos/telesis_logo.png"
import InterregLogo from "../../images/logos/interreg.png"
import HfaLogo from "../../images/logos/hfa_hoch_Logo_.png"
import WkoLogo from "../../images/logos/WKV4C.png"
import WaldverbandLogo from "../../images/logos/waldverband vorarlberg logo.jpg"
import VorarlbergLogo from "../../images/logos/Logo-auf-Weiss-RGB.jpg"
import WaldsaegeLogo from "../../images/logos/Wald-Saege_Fuchstal_Schriftzug.png"

const LogoCarousel = () => {
  const customerLogo = useRef(null)

  return (
    <Container>
      <Rerousel itemRef={customerLogo} interval={6000}>
        <div ref={customerLogo} className="rerousel-item">
          <a
            href="https://vorarlberg.at/land-forstwirtschaft"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-75 logoImage"
              src={VorarlbergLogo}
              alt="Land Vorarlberg Logo"
            />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://telesis.at/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-75 logoImage" src={TelesisLogo} alt="Telesis Logo" />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://www.holzforum-allgaeu.de/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-75 logoImage" src={HfaLogo} alt="Holzverband Allgäu" />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://www.interreg.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-75 logoImage" src={InterregLogo} alt="Interreg Europa" />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://www.wko.at/service/vbg/Wirtschaftskammer-Vorarlberg.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="w-75 logoImage" src={WkoLogo} alt="WKO Logo" />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://www.waldverband.at/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-75 logoImage"
              src={WaldverbandLogo}
              alt="Waldverband Vorarlberg Logo"
            />
          </a>
        </div>
        <div className="rerousel-item">
          <a
            href="https://www.wald-saege-fuchstal.de/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="w-75 logoImage"
              src={WaldsaegeLogo}
              alt="Waldsaege Fuchstal"
            />
          </a>
        </div>
      </Rerousel>
    </Container>
  )
}

export default LogoCarousel

/*
      <Carousel control={0}>
        <Carousel.Item>
          <img className="w-25" src={VorarlbergLogo} alt="Land Vorarlberg Logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-25" src={TelesisLogo} alt="Telesis Logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-25" src={HfaLogo} alt="Holzverband Algäu" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-25" src={InterregLogo} alt="Interreg Europa" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="w-25" src={WkoLogo} alt="WKO Logo" />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-25"
            src={WaldverbandLogo}
            alt="Waldverband Vorarlberg Logo"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-25"
            src={WaldsaegeLogo}
            alt="Waldsaege Fuchstal Logo"
          />
        </Carousel.Item>
      </Carousel>

      */
